'use client';

import styles from './footer.module.scss';
import MoshyLogo from '../../MoshyLogo/MoshyLogo';
import MoshyLogoLink from '../../MoshyLogoLink/MoshyLogoLink';
import SocialMediaList from '../../SocialMediaList/SocialMediaList';
import FooterNavigation from './FooterNavigation';
import Copyright from '../../Copyright/Copyright';
import { Waypoint } from 'react-waypoint';
import { LegitScript } from '@get-mosh/msh-shared';

const Footer = () => {
    const handleFooterEntering = (direction?: string) => {
        document.body.setAttribute(
            'data-footer-entered',
            direction === 'in' ? 'true' : 'false',
        );
    };
    return (
        <footer className={styles['footer']}>
            <Waypoint
                onEnter={() => handleFooterEntering('in')}
                onLeave={() => handleFooterEntering('out')}
            >
                <div>
                    <div className={styles['footer--wrapper']}>
                        <div className={styles['footer--wrapper-logo']}>
                            <MoshyLogo variant={'white'} />
                            <MoshyLogoLink />
                        </div>
                        <div className={styles['footer--wrapper-navigation']}>
                            <FooterNavigation />
                        </div>
                    </div>
                    <div className={styles['copyright--wrapper']}>
                        <SocialMediaList />
                        <Copyright />
                    </div>
                    <LegitScript id="18705271" />
                </div>
            </Waypoint>
        </footer>
    );
};

export default Footer;
