import Routes from 'src/constants/Routes';
import { useSessionActive } from '@get-mosh/msh-shared';

type LoginLinkProps = {
    className?: string;
};

const LoginLink = ({ className }: LoginLinkProps) => {
    const sessionActive = useSessionActive();

    const linkPath = sessionActive ? Routes.NOTIFICATIONS : Routes.SIGN_IN();
    const linkLabel = sessionActive ? 'My account' : 'Login';

    return (
        <a className={className} href={linkPath} title={linkLabel}>
            {linkLabel}
        </a>
    );
};

export default LoginLink;
